<template>
  <div id="content">
    <form v-on:submit.prevent="onSubmit" @submit="submitForm">
      <h2>Sign up</h2>
      <p class="errors" v-if="errors.length">
        <ul>
          <li v-for="error in errors" :key="error.key">{{ error }}</li>
        </ul>
      </p>

      <div class="control">
        <input v-model="email" type="email" placeholder="E-mail" name="user[email]" id="login_form_email">
      </div>

      <div class="control">
        <input v-model="password" placeholder="Password" type="password" name="user[password]" id="login_form_password">
      </div>

      <div class="control">
        <input v-model="password_confirmation" placeholder="Password again" type="password" name="user[password_confirmation]" id="login_form_password">
      </div>

      <div class="center">
        <button type="submit" class="button reverse">
          <inline-svg
            :src="require('../../assets/icons/login.svg')"
          ></inline-svg>
          Sign up
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Auth#Login',
  data() {
    return {
      errors: [],
      email: '',
      password: '',
      password_confirmation: '',
      active: false
    }
  },
  methods: {
    submitForm: function (e) {

    if(this.active)
    {
      return
    }
    this.active = true

    var self = this

    fetch(process.env.VUE_APP_API_BASE_URL + '/auth/signup.json', {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ user: { email: this.email, password: this.password, password_confirmation: this.password_confirmation } })
    }).then(res => {
        if(res.status < 300) {
          console.log(res.json())
        } else {
          res.json().then(data => {
            self.errors = [];

            var keys = Object.keys(data);
            keys.forEach(key => {
              self.errors.push(`${key} ${data[key]}`)
            });
          });
        }
        self.active = false
      });

      e.preventDefault();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass" scoped>
#content
  z-index: 10
  position: relative
  max-width: 500px
  margin: 0 auto
  margin-bottom: 0px
  text-align: left
  color: #333
  border-radius: 30px
  padding: 40px 40px
  background-color: white
  margin-bottom: 2rem

  h2
    text-align: center
    margin-bottom: 4rem

  form
    margin: 0

  .control
    margin-bottom: 2rem

  .errors
    font-size: 1.7rem
    color: red

    li
      margin-bottom: 0
      font-weight: 500
</style>
